




















































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiGoodsCommentDel,
    apiGoodsCommentLists,
    apiGoodsCommentStatus,
    apiGoodsCommentReply
} from '@/api/goods'
import { RequestPaging, timeFormat } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        DatePicker,
        ExportData
    }
})
export default class Evaluation extends Vue {
    $refs!: { table: any }
    pager = new RequestPaging()
    selectIds: any[] = []
    reply = ''

    queryObj: any = {
        goods_info: '',
        user_info: '',
        reply_status: '',
        verify_status: '',
        start_time: '',
        end_time: '',
        comment_type: ''
    }
    apiGoodsCommentLists = apiGoodsCommentLists
    get disabledBtn() {
        return !this.selectIds.length
    }

    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiGoodsCommentLists,
            params: {
                ...this.queryObj
            }
        })
    }
    get prevImage() {
        return (lists: any[]) => {
            return lists.map(item => item.uri)
        }
    }
    // 全选商品
    handleselectAll() {
        this.$refs.table.toggleAllSelection()
    }
    handleReset() {
        this.queryObj = {
            goods_info: '',
            user_info: '',
            reply_status: '',
            verify_status: '',
            start_time: '',
            end_time: '',
            comment_type: ''
        }
        this.getList()
    }
    handleSelect(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }
    handleReply(id: any[]) {
        apiGoodsCommentReply({
            id,
            reply: this.reply
        }).then(() => {
            this.getList()
        })
    }
    handdleStatus(value: number, id: any[]) {
        apiGoodsCommentStatus({
            id,
            status: value
        }).then(() => {
            this.getList()
        })
    }

    handleDelete(id: any) {
        apiGoodsCommentDel({
            id
        }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
}
